<template>
  <div class="uploadVideo">
    <div class="uploadVideo_top">
      <UploadVideo @duration="duration" :queryId="queryId"></UploadVideo>
      <div class="fac tips">
        <div class="tip fac" :class="{ active: queryId == 1 }" @click="tipClick(1)">
          <div class="img"></div>
          <span>青年演说家</span>
        </div>
        <div class="tip fac" :class="{ active: queryId == 2 }" @click="tipClick(2)">
          <div class="img"></div>
          <span>知识大讲堂</span>
        </div>
      </div>
    </div>
    <div class="list">
      <ul class="flex jsb">
        <li>
          <p>视频时长</p>
          <span v-if="queryId == 1">视频时长{{ getDataConfig.smallVIdeoMin }}-{{ getDataConfig.smallVIdeoMax }}秒</span>
          <span v-if="queryId == 2">视频时长{{ getDataConfig.fullVideoMin / 60 }}分钟-{{ getDataConfig.fullVideoMax / 60 }}分钟</span>
        </li>
        <li>
          <p>视频格式</p>
          <span>最佳视频文件为mp4 vag 形式</span>
        </li>
        <li>
          <p>视频分辨率</p>
          <span>
            <span v-if="queryId == 1">1280*720 720*480</span>
            <span v-if="queryId == 2">
              1280*1080
              <br />高光时刻：1024*720 720*480
              <br />
            </span>
            低于推荐分辨率将影响观看效果~
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UploadVideo from '@/components/public/UploadVideo.vue';
import { getVideoConfig } from '@/utils/serveApi.js';
import { localStorages } from '@/utils/public.js';
export default {
  name: 'uploadVideo',
  data() {
    return {
      activeIndex: 0,
      queryId: 0,
      isClick: 0,
      videoUrl: {},
      getDataConfig: {}
    };
  },
  created() {
    this.getVideoConfig();
  },
  methods: {
    async getVideoConfig() {
      // 视频时长
      const { data } = await getVideoConfig();
      this.getDataConfig = data;
      localStorages('getDataConfig', data);
    },
    tipClick(index) {
      this.queryId = index;
    },
    duration(obj) {
      const { isIndex, url, videoId } = obj;
      if (isIndex) {
        this.queryId = isIndex;
      } else {
        this.$router.push({ name: 'postVideo', query: { queryId: this.queryId, fileRaw: url, videoInitId: videoId } });
      }
    }
  },
  components: {
    UploadVideo
  }
};
</script>

<style lang="scss" scoped>
.uploadVideo {
  padding: 40px;
  &_top {
    background-color: #eef2f5;
    border-radius: 10px;
    padding: 40px;
    .tips {
      justify-content: space-around;
      .tip {
        font-size: 24px;
        color: #999999;
        cursor: pointer;
        margin-top: 40px;
        .img {
          width: 24px;
          height: 24px;
          padding-right: 12px;
          background: url('../../assets/img/creatorPlatform/icon4.png') no-repeat;
          background-size: contain;
        }
      }
      .tip.active {
        color: #ffb600;
        .img {
          background: url('../../assets/img/creatorPlatform/icon3.png') no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .list {
    ul {
      margin: 40px -32px;
      li {
        background-color: #eef2f5;
        border-radius: 10px;
        padding: 20px;
        margin: 0 32px;
        flex: 1;
        p {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          padding-bottom: 14px;
        }
        span {
          color: #999999;
        }
      }
    }
  }
}
</style>
